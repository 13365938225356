import request from '@/utils/request'
// ----------------------------------管理端-------------------------------------

//创建、修改文章分类
export function reviewartClass(data) {
    return request({
        url: '/reviewartclass',
        method: 'post',
        data
    })
}

// 文章分类查询
export function getartClass(data) {
    return request({
        url: '/getartclass',
        method: 'get',
        params: data
    })
}

// 文章分类删除
export function deleteartClass(data) {
    return request({
        url: '/deleteartclass',
        method: 'delete',
        params: data
    })
}
// ----------------------------

// 创建文章
export function reviewart(data) {
    return request({
        url: '/reviewart',
        method: 'post',
        data
    })
}

// 查询文章
export function getart(data) {
    return request({
        url: '/getart',
        method: 'get',
        params: data
    })
}
// 删除文章
export function deleteart(data) {
    return request({
        url: '/deleteart',
        method: 'delete',
        params: data
    })
}

// ----------------------------------用户端-------------------------------------

// 查询分类
export function getuserartclass(data) {
    return request({
        url: '/getuserartclass',
        method: 'get',
        params: data
    })
}
// 查询文章
export function getuserart(data) {
    return request({
        url: '/getuserart',
        method: 'get',
        params: data
    })
}

// 用户喜欢文章
export function likeart(data) {
    return request({
        url: '/likeart',
        method: 'post',
        data
    })
}
